<template>
    <div align="center">
        <div class="text-h4">Registro dei movimenti</div>
        <br>
        Pratica N. {{pratica.numero}}
        <br>
        <hr>

        <div class="row">

            <q-timeline
                layout="comfortable"
                side="right"
                color="secondary"
                class="col-md-8 offset-md-3"
            >
                <q-timeline-entry
                    :subtitle="getDataEvento(movimento.data_evento)"
                    v-for="(movimento,index) in registro_movimenti" v-bind:key="index"
                >
                    <q-list bordered >
                        <q-item>
                            <q-item-section>
                                <q-item-label caption>{{movimento.operatore}}</q-item-label>
                                <q-item-label v-html="movimento.descrizione"></q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-timeline-entry>
            </q-timeline>

        </div>

        <br><br><hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onClickIndietro()"
                />
            </div>
        </div>
        <br><br><br><br><br><br>
    </div>

</template>

<script>
    import { mapState, mapActions } from "vuex";
    import { mapFields} from 'vuex-map-fields';
    import QQButton from "@/components/QQButton.vue";

    export default {
        name: "RegistroMovimenti",
        data() {
            return {
                registro_movimenti: []
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                //registro_movimenti: state => state.registro_movimenti
            }),
            ...mapFields("gestioneFatture", {
                cliente: "cliente",
                pratica: "pratica",
                contratto: "contratto",
                quietanza: "quietanza",
                fattura: "fattura",
                dati_economici: "dati_economici",
                pagina_sorgente: "pagina_sorgente"
            })

        },
        methods: {
            ...mapActions({
                GetRegistroMovimenti: "gestioneFatture/GetRegistroMovimenti"
            }),
            onClickIndietro() {
                this.$router.push({name : "Fatture.SchedaFattura"});
            },
            getDataEvento(value) {
                var data = new Date(value);
                var giorno_settinana = data.getDay();
                var giorno = "";
                switch (parseInt(giorno_settinana)) {
                    case 0: giorno = "Domenica"; break;
                    case 1: giorno = "Lunedi"; break;
                    case 2: giorno = "Martedi"; break;
                    case 3: giorno = "Mercoledi"; break;
                    case 4: giorno = "Giovedi"; break;
                    case 5: giorno = "Venerdi"; break;
                    case 6: giorno = "Sabato"; break;
                }

                return giorno + " - " + data.getDate() + "/" + (data.getMonth() + 1) + "/" + data.getFullYear() + " - " + data.getHours() + ":" + data.getMinutes()+":"+data.getSeconds();
            }
        },
        async mounted() {
            this.is_loading = true;
            console.log("fattura:",this.fattura);
            this.registro_movimenti = await this.GetRegistroMovimenti(this.fattura.idfattura);
        }
    }
</script>

<style scoped>
    td.domanda {
        text-align: left;
        font-style: italic;
        border-bottom: 1px dashed #c0c0c0;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        font-weight: bold;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered tbody tr td, .table tr th {
        /*border: 1px solid #404040;*/
        border: 1px solid;
        padding: 8px;
    }

    .table-bordered thead tr th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        /*color: #404040;
        background-color: #D0D0D0;*/
        border: 1px SOLID #404040;
        background-color: #ffcc99;
        color: #000;
    }

    .table-bordered tFoot tr td {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        padding-right: 8px;
        text-align: right;
        border: 1px SOLID;

    }
</style>